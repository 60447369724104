import React, { useState } from 'react';
import DreamscapeBanner from '../../components/ds-banner/DreamscapeBanner';
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Paper,
  TextField,
  Theme
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { validateEmail } from '../../utils/email';
import Button from '../../components/button/Button';
import { fetchApi } from '../../redux/actions/fetch-actions';
import { APIMethods } from '../../types/fetch/fetch-types';
import { useDispatch } from 'react-redux';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100vh',
    width: '100%'
  },
  button: {
    width: '40%',
    marginTop: '10px',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  form: {
    width: '40%',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  message: {
    width: '40%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }
}));

const CancelMembershipEmailView: React.FC<any> = () => {
  const [email, setEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);
  const [error, setError] = useState({
    isError: false,
    errorText: ''
  });
  const dispatch = useDispatch();
  const classes = useStyles();

  const reset = () => {
    setIsEmailSent(false);
    setEmail('');
  };

  const isEmailValid = (emailInput: string) => {
    if (emailInput === '' || !validateEmail(emailInput)) {
      setError({
        errorText: 'Please enter a valid email',
        isError: true
      });
      return false;
    } else {
      setError({
        errorText: '',
        isError: false
      });
      return true;
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue = event.target.value;
    const trimmedEmailString = emailValue.trim();
    isEmailValid(trimmedEmailString);
    setEmail(trimmedEmailString);
  };

  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    sendEmail();
  };

  const sendEmail = () => {
    if (isEmailValid(email)) {
      setLoading(true);
      dispatch(
        fetchApi({
          url: 'sw/canceltoken/cancel/link',
          method: APIMethods.POST,
          data: {
            email
          }
        })
      )
        .then(() => {
          setLoading(false);
          setIsEmailSent(true);
        })
        .catch(() => {
          setLoading(false);
          setIsEmailSent(true);
        });
    }
  };

  return (
    <div className={classes.root}>
      <DreamscapeBanner title={'Cancel Membership'} />
      <Grid
        container
        style={{ display: 'flex', height: '100%' }}
        justify={'center'}
        alignItems={'center'}
      >
        {loading ? (
          <CircularProgress color={'primary'} />
        ) : isEmailSent ? (
          <>
            <Paper
              className={classes.message}
              style={{ padding: '2rem', marginBottom: '70px' }}
            >
              <p>
                If this email has a membership associated with it you will
                receive an email with further instructions on how to cancel
                memberships.
              </p>
              <br />
              <p>
                If you did not receive the email, please contact{' '}
                <a href="mailto:cancellations@shoelacelearning.com">
                  cancellations@shoelacelearning.com
                </a>{' '}
                for further assistance.
              </p>
              <br />
              <p>
                <b>
                  NOTE: If the membership was purchased through Apple Store or
                  Google Play Store, the cancellation will have to be through
                  Apple or Google directly. Below are some links to articles
                  that will help you through the cancellation process for{' '}
                  <a
                    target="_blank"
                    href="https://support.google.com/googleplay/answer/7018481?co=GENIE.Platform%3DAndroid&hl=en"
                  >
                    Google Play
                  </a>{' '}
                  or{' '}
                  <a
                    target="_blank"
                    href="https://support.apple.com/en-ca/HT202039"
                  >
                    Apple App Store
                  </a>
                  .
                </b>
              </p>
              <div>
                <IconButton onClick={reset}>
                  <ArrowBackIcon />
                </IconButton>
              </div>
            </Paper>
          </>
        ) : (
          <>
            <form onSubmit={handleSubmit} className={classes.form}>
              <h3 style={{ marginBottom: '1rem' }}>
                Please enter the email address used to make the Dreamscape
                membership purchase.
              </h3>
              <TextField
                error={error.isError}
                name="email"
                style={{ minWidth: '100%' }}
                onChange={handleChange}
                value={email}
                label="Your Email"
                onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                  if (event.key === 'Enter') {
                    sendEmail();
                  }
                }}
                variant="outlined"
              />
              {error.isError && (
                <FormHelperText
                  style={{ textAlign: 'center' }}
                  error={error.isError}
                >
                  {error.isError && error.errorText}
                </FormHelperText>
              )}
              <Button type="submit" className={classes.button} primary={true}>
                Send Email
              </Button>
            </form>
          </>
        )}
      </Grid>
    </div>
  );
};

export default CancelMembershipEmailView;
